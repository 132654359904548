import { FC } from 'react';

interface HomePageSectionHeadingProps {
	headingText: string;
	subheadingText: string;
}

export const HomePageSectionHeading: FC<HomePageSectionHeadingProps> = ({
	headingText,
	subheadingText,
}) => (
	<header className="mb-12 space-y-4 md:space-y-8">
		<h2 className="text-base font-bold md:text-1.5xl">{headingText}</h2>
		<h3 className="text-3xl font-bold leading-normal text-voltage md:text-4xl">
			{subheadingText}
		</h3>
	</header>
);
