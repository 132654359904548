import React, { FC } from 'react';

import { HomePageSectionHeading } from '@/components/pages/home/HomePageSectionHeading';
import { Link } from '@/components/shared/Link';
import { BusinessInsiderLogo } from '@/components/svgs/BusinessInsiderLogo';
import { EnergyShiftLogo } from '@/components/svgs/EnergyShiftLogo';
import { NikkeiLogo } from '@/components/svgs/NikkeiLogo';

export const HomepageSectionMedia: FC = () => (
	<section className="flex flex-col space-y-6 md:space-y-12">
		{/* This, that and the unique charm of Octopus is here. */}
		<HomePageSectionHeading
			headingText="🐙 メディア掲載"
			subheadingText="こんなところ、あんなところ、オクトパスならではの魅力がココに"
		/>
		<div className="grid grid-rows-3 items-center gap-y-6 text-center text-lg font-bold md:grid-cols-3 md:grid-rows-none md:gap-x-6">
			<Link
				isButton
				variant="text"
				color="tertiary"
				href="https://www.nikkei.com/article/DGXZQODK045930U2A400C2000000/"
				target="_blank"
			>
				<div className="flex flex-1 flex-col">
					<div className="flex h-48 items-center justify-center rounded-t-xl bg-blueberry p-8">
						<h4>
							電力市場に変革起こせるか英オクトパスが始動
							{/* Can the electricity market be transformed? */}
						</h4>
					</div>
					<div className="flex h-24 items-center justify-center rounded-b-xl bg-hemocyanin p-6">
						<NikkeiLogo />
					</div>
				</div>
			</Link>
			<Link
				isButton
				href="https://energy-shift.com/news/835b2a0d-06bc-41a7-a3ca-4024bab0f533"
				target="_blank"
				variant="text"
				color="tertiary"
			>
				<div className="flex flex-1 flex-col">
					<div className="flex h-48 items-center justify-center rounded-t-xl bg-blueberry p-8">
						<h4>
							グリーン電力プランの方がお得！？
							再エネ小売の黒船、オクトパスエナジーの日本戦略とは
							{/* Green electricity plans are more affordable! What is Octopus Energy's Japan strategy, the black ship of renewable energy retailing? */}
						</h4>
					</div>
					<div className="flex h-24 grow items-center justify-center rounded-b-xl bg-hemocyanin p-6">
						<EnergyShiftLogo />
					</div>
				</div>
			</Link>
			<Link
				isButton
				color="tertiary"
				variant="text"
				href="https://www.businessinsider.jp/post-254683"
				target="_blank"
			>
				<div className="flex flex-1 flex-col">
					<div className="flex h-48 items-center justify-center rounded-t-xl bg-blueberry p-8">
						<h4>
							｢電力会社のファンになる｣。ありえない光景がイギリスで生まれている訳
							{/* 'Become a fan of power companies'. Why an impossible scene is being created in the UK. */}
						</h4>
					</div>
					<div className="flex h-24 items-center justify-center rounded-b-xl bg-hemocyanin p-6">
						<BusinessInsiderLogo />
					</div>
				</div>
			</Link>
		</div>
	</section>
);
